var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "表格显示列",
        width: "40%",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-table",
        {
          ref: "fitlerTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.columns,
            height: "330px",
            "tooltip-effect": "dark",
            "header-align": "left",
            align: "left",
            size: _vm.size
          },
          on: { "selection-change": _vm.selectionChange }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "40" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "属性" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.prop))]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "列名" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-input", {
                      attrs: { size: _vm.size },
                      model: {
                        value: scope.row.label,
                        callback: function($$v) {
                          _vm.$set(scope.row, "label", $$v)
                        },
                        expression: "scope.row.label"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "最小宽度" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-input", {
                      attrs: { size: _vm.size },
                      model: {
                        value: scope.row.minWidth,
                        callback: function($$v) {
                          _vm.$set(scope.row, "minWidth", $$v)
                        },
                        expression: "scope.row.minWidth"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: _vm.size },
              nativeOn: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("action.cancel")))]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: _vm.size, type: "primary" },
              nativeOn: {
                click: function($event) {
                  return _vm.handleFilterColumns.apply(null, arguments)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("action.confirm")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-708e08bb", { render: render, staticRenderFns: staticRenderFns })
  }
}