<template>
  <div id="main-container" v-if="isFullscreen" class="main-full-screen-container" >
    <!-- 主内容区域 -->
    <div class="main-full-screen-content">
      <!-- <transition name="fade" mode="out-in"> -->
      <keep-alive>
        <router-view v-if='$route.meta.keepAlive'></router-view>
      </keep-alive>
      <router-view v-if='!$route.meta.keepAlive'></router-view>
      <!-- </transition> -->
    </div>
  </div>
  <div v-else id="main-container" :class="[computedClass]" >
    <!-- 标签页 -->
    <!-- <div class="tab-container">
      <el-tabs class="tabs" :class="$store.state.app.collapse?'position-collapse-left':'position-left'"
        v-model="mainTabsActiveName" :closable="true" type="card"
        @tab-click="selectedTabHandle" @tab-remove="removeTabHandle">
        <el-dropdown class="tabs-tools" :show-timeout="0" trigger="hover">
          <div style="font-size:20px;width:50px;"><i class="el-icon-arrow-down"></i></div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="tabsCloseCurrentHandle">关闭当前标签</el-dropdown-item>
            <el-dropdown-item @click.native="tabsCloseOtherHandle">关闭其它标签</el-dropdown-item>
            <el-dropdown-item @click.native="tabsCloseAllHandle">关闭全部标签</el-dropdown-item>
            <el-dropdown-item @click.native="tabsRefreshCurrentHandle">刷新当前标签</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-tab-pane v-for="item in mainTabs"
          :key="item.name" :label="item.title" :name="item.name">
          <span slot="label"><i :class="item.icon"></i> {{item.title}} </span>
        </el-tab-pane>
      </el-tabs>
    </div> -->
    <!-- 主内容区域 -->
    <div :class="isAion?'main-ai-content':'main-no-ai-content'">
      <!-- <transition name="fade" mode="out-in"> -->
        <keep-alive>
          <router-view v-if='$route.meta.keepAlive'></router-view>
        </keep-alive>
        <router-view v-if='!$route.meta.keepAlive'></router-view>
      <!-- </transition> -->
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import screenfull from "screenfull";

export default {
  data()
  {
    return {}
  },
  computed: {
    ...mapState({
      isFullscreen: state => state.app.isFullscreen,
      isAion: state => state.ai.isAion,
      collapse: state => state.app.collapse
    }),
    computedClass() {
      if (this.isAion) {
        if (this.collapse) {
          return 'main-ai-container position-collapse-left';
        }
        else {
          return 'main-ai-container position-left';
        }
      }
      else {
        if (this.collapse) {
          return 'main-no-ai-container position-collapse-left';
        }
        else {
          return 'main-no-ai-container position-left';
        }
      }
    },
    mainTabs: {
      get()
      {
        return this.$store.state.tab.mainTabs
      },
      set(val)
      {
        this.$store.commit('updateMainTabs', val)
      }
    },
    mainTabsActiveName: {
      get()
      {
        return this.$store.state.tab.mainTabsActiveName
      },
      set(val)
      {
        this.$store.commit('updateMainTabsActiveName', val)
      }
    }
  },
  mounted() {
    //alert(sessionStorage.getItem('isFullscreen'));
    if (sessionStorage.getItem('isFullscreen')) {
      // 改变全屏状态.
      this.$store.commit('setIsFullscreen', sessionStorage.getItem('isFullscreen'))
    }
    else {
      // do nothing.
      this.$store.commit('setIsFullscreen', false)
    }
  },
  methods: {
    // tabs, 选中tab
    /*
    selectedTabHandle(tab)
    {
      tab = this.mainTabs.filter(item => item.name === tab.name)
      if (tab.length >= 1)
      {
        this.$router.push({name: tab[0].name})
      }
    },

     */
    // tabs, 删除tab,不针对待办。
    removeTabHandle(tabName)
    {
      this.mainTabs = this.mainTabs.filter(item => item.name !== tabName)
      if (this.mainTabs.length >= 1)
      {
        // 当前选中tab被删除
        if (tabName === this.mainTabsActiveName)
        {
          this.$router.push({name: this.mainTabs[this.mainTabs.length - 1].name}, () =>
          {
            this.mainTabsActiveName = this.$route.name
          })
        }
      }
      else
      {
        this.$router.push("/")
      }
    },
    // tabs, 删除tab,仅仅针对待办。
    removeTabHandleFirst(tabName)
    {
      //删除待办
      this.mainTabs = this.mainTabs.filter(item => item.name !== tabName)
      this.mainTabsActiveName = ''

      //新增空白页
      //add new tab
      let tab = {
        name: '空白',
        title: '空白',
        icon: 'fa fa-home fa-lg'
      }
      this.mainTabs = this.mainTabs.concat(tab)
      this.mainTabsActiveName = tab.name

      //打开空白页.
      this.$router.push({name: '空白'}, () =>
      {
        this.mainTabsActiveName = this.$route.name
      })

    },

    // tabs, 删除tab,仅仅针对空白页。
    removeTabHandleBlank()
    {
      //删除空白页
      this.mainTabs = this.mainTabs.filter(item => item.name !== '空白')
    },

    // tabs, 关闭当前
    /*
    tabsCloseCurrentHandle()
    {
      this.removeTabHandle(this.mainTabsActiveName)
    },

     */
    // tabs, 关闭其它
    /*
    tabsCloseOtherHandle()
    {
      this.mainTabs = this.mainTabs.filter(item => item.name === this.mainTabsActiveName)
    },

     */
    // tabs, 关闭全部
    /*
    tabsCloseAllHandle()
    {
      this.mainTabs = []
      this.$router.push("/")
    },

     */
    // tabs, 刷新当前
    // 此方法无法使用在待办上，因为待办是一直保留的页面，并且不会被关闭.
    tabsRefreshCurrentHandle()
    {
      var tempTabName = this.mainTabsActiveName
      //删除当前tab
      this.removeTabHandle(tempTabName)
      //然后重新打开当前tab完成刷新.因为该菜单存在在导航栏中，所以导航栏中会触发自动添加tab。
      this.$nextTick(() =>
      {
        //TODO: 2024.01.05. stone zhao 因为使用keep-alive保存了页面缓存，所以此处进行全页面的全部刷新。
        self.location = this.global.baseLoginUrl;
        //this.$router.push({name: tempTabName})
      })
    },

    // tabs, 刷新当前
    // 此方法针对待办的特殊处理。
    tabsRefreshCurrentHandleFirst()
    {
      var tempTabName = this.mainTabsActiveName

      //删除当前tab,新增一个空白页，因为需要一个空白页进行回退。
      this.removeTabHandleFirst(tempTabName)

      //然后重新打开当前tab完成刷新.由于待办和空白都不存在菜单中，所以不会自己增加tab，需要手动增加tab
      this.$nextTick(() =>
      {
        //add new tab
        let tab = {
          name: '待办',
          title: '待办',
          icon: 'fa fa-home fa-lg'
        }
        this.mainTabs = this.mainTabs.concat(tab)
        this.mainTabsActiveName = tab.name
        this.$nextTick(() =>
        {
          this.removeTabHandleBlank()
          this.$router.push({name: tempTabName})
        })
      })
    }
  }
}
</script>

<!--<style scoped lang="scss">-->
<!--.main-container {-->
<!--  padding: 0 5px 5px;-->
<!--  position: absolute;-->
<!--  top: 64px;-->
<!--  left: 1px;-->
<!--  right: 1px;-->
<!--  bottom: 0px;-->
<!--  // background: rgba(56, 5, 114, 0.5);-->
<!--  //   .tabs {-->
<!--  //     position: fixed;-->
<!--  //     top: 60px;-->
<!--  //     right: 50px;-->
<!--  //     padding-left: 0px;-->
<!--  //     padding-right: 2px;-->
<!--  //     z-index: 1020;-->
<!--  //     height: 40px;-->
<!--  //     line-height: 40px;-->
<!--  //     font-size: 14px;-->
<!--  //     background: rgb(255, 253, 255);-->
<!--  //     border-color: rgba(200, 206, 206, 0.5);-->
<!--  //     // border-left-width: 1px;-->
<!--  //     // border-left-style: solid;-->
<!--  //     border-bottom-width: 1px;-->
<!--  //     border-bottom-style: solid;-->
<!--  //   }-->
<!--  //  .tabs-tools {-->
<!--  //     position: fixed;-->
<!--  //     top: 60px;-->
<!--  //     right: 0;-->
<!--  //     z-index: 1020;-->
<!--  //     height: 40px;-->
<!--  //     // padding: 0 10px;-->
<!--  //     font-size: 14px;-->
<!--  //     line-height: 40px;-->
<!--  //     cursor: pointer;-->
<!--  //     border-color: rgba(200, 206, 206, 0.5);-->
<!--  //     border-left-width: 1px;-->
<!--  //     border-left-style: solid;-->
<!--  //     border-bottom-width: 1px;-->
<!--  //     border-bottom-style: solid;-->
<!--  //     background: rgba(255, 255, 255, 1);-->
<!--  //   }-->
<!--  //   .tabs-tools:hover {-->
<!--  //     background: rgba(200, 206, 206, 1);-->
<!--  //   }-->
<!--  .main-content {-->
<!--    position: absolute;-->
<!--    top: 45px;-->
<!--    left: 5px;-->
<!--    right: 5px;-->
<!--    bottom: 5px;-->
<!--    padding: 5px;-->
<!--    // background: rgba(209, 212, 212, 0.5);-->
<!--  }-->
<!--}-->

<!--//.position-left {-->
<!--//  left: 200px;-->
<!--//}-->
<!--//-->
<!--//.position-collapse-left {-->
<!--//  left: 65px;-->
<!--//}-->
<!--</style>-->
