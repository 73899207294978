export default {
  state: {
    aiName: "康养智能体",  // AI应用名称
    isAion: false  // 是否开启AI对话界面,默认不开启.
  }, getters: {
  }, mutations: {
    setIsAion (state, isAion) {
      // 改变AI状态.
      state.isAion =isAion
    }
  }, actions: {}
}
