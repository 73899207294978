var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isFullscreen,
          expression: "!isFullscreen"
        }
      ],
      staticClass: "ai-container"
    },
    [
      _c("iframe", {
        ref: "my-iframe",
        staticClass: "ai-frame",
        attrs: {
          src: _vm.src,
          scrolling: "auto",
          frameborder: "0",
          onload: _vm.onloaded()
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-039bf227", { render: render, staticRenderFns: staticRenderFns })
  }
}