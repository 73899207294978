<template>
  <div class="ai-container" v-show="!isFullscreen" >
      <iframe ref="my-iframe" :src="src" scrolling="auto" frameborder="0" class="ai-frame" :onload="onloaded()">
      </iframe>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Cookies from "js-cookie";

let token = Cookies.get('token')

export default {
  data() {
    return {
      token : Cookies.get('token'),
      currentOrg: sessionStorage.getItem('currentOrgId'),
      //src: "http://localhost:5173",
      src: "https://oa.highblue.top",
      loading: null
    }
  },
  computed: {
    ...mapState({
      isFullscreen: state => state.app.isFullscreen
    }),
  },
  methods: {

    handleMessage (event) {
      var that = this;
      // 根据上面制定的结构来解析 iframe 内部发回来的数据
      const data = event.data
      switch (data.cmd) {
        case 'ready-for-receiving':
          // 业务逻辑


          break
      }

      if (event.data === "childLoaded") {
        console.log("父页面收到数据");
        console.log(event.data);//子组件发过来的数据
        console.log("父页面发送数据");
        var iFrame = that.$refs['my-iframe'];
        iFrame.contentWindow.postMessage(
          { token: this.token, currentOrg: this.currentOrg },//可以以对象形式传参，也可以直接传字符串"aaa"
          '*'//嵌入页面的路径,也可以直接传"*"
        );
      }

    },

    // 获取路径
    resetSrc: function(url) {
      this.src = url
      this.load()
    },
    load: function() {
      this.loading = this.$loading({
        lock: true,
        text: "loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
        // fullscreen: false,
        target: document.querySelector("#main-container ")
      })
    },
    onloaded: function() {
      if(this.loading) {
        this.loading.close()
      };
    }
  },
  mounted() {
    this.resetSrc(this.src);
  },
  created() {
    // 父页面监听iframe消息
    window.addEventListener(
      "message",
      this.handleMessage,
      false
    );
  },
  destroyed () {
    // 注意移除监听！注意移除监听！注意移除监听！
    window.removeEventListener('message', this.handleMessage)
  },
  watch: {
    $route: {
      handler: function(val, oldVal) {
        // 如果是跳转到嵌套页面，切换iframe的url
        this.resetSrc(this.src);
      }
    }
  }
}
</script>

<style lang="scss">
.ai-container {
  position: relative;
  top: 64px;
  left: 40%;
  right: 0;
  bottom: 0px;
  padding: 0px;
  height: 100%;


  .ai-frame {
    width: 20%;
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
  }
}
</style>

