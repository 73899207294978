var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "toolbar", staticStyle: { padding: "10px" } },
        [
          _c(
            "el-form",
            {
              attrs: {
                inline: true,
                model: _vm.filters,
                size: _vm.size,
                align: "left"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "照护区域", prop: "careAreaId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "请选择照护区域",
                        filterable: "",
                        clearable: ""
                      },
                      model: {
                        value: _vm.filters.careAreaId,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "careAreaId", $$v)
                        },
                        expression: "filters.careAreaId"
                      }
                    },
                    _vm._l(_vm.careAreaFilters, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "工单类型", prop: "customerName" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "请选择工单类型",
                        filterable: "",
                        clearable: ""
                      },
                      model: {
                        value: _vm.filters.workordertypeDictId,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "workordertypeDictId", $$v)
                        },
                        expression: "filters.workordertypeDictId"
                      }
                    },
                    _vm._l(_vm.workordertypes, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.label, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "长者姓名", prop: "customerName" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "请选择长者",
                        filterable: "",
                        clearable: ""
                      },
                      model: {
                        value: _vm.filters.customerId,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "customerId", $$v)
                        },
                        expression: "filters.customerId"
                      }
                    },
                    _vm._l(_vm.customers, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("kt-button", {
                    attrs: {
                      icon: "fa fa-search",
                      label: _vm.$t("action.search"),
                      perms: "workorder:workorder:view",
                      type: "primary"
                    },
                    on: {
                      click: function($event) {
                        return _vm.findPage(null)
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("kt-button", {
                    attrs: {
                      icon: "fa fa-plus",
                      label: "紧急工单",
                      perms: "workorder:workorder:add",
                      type: "primary"
                    },
                    on: { click: _vm.handleAdd }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("kt-button", {
                    attrs: {
                      icon: "fa fa-edit",
                      label: _vm.$t("action.nessWorkorder"),
                      perms: "workorder:workorder:view",
                      type: "primary"
                    },
                    on: {
                      click: function($event) {
                        return _vm.generateNessWorkorder()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("kt-table", {
        attrs: {
          permsEdit: "workorder:workorder:edit",
          permsDelete: "workorder:workorder:delete",
          data: _vm.pageResult,
          columns: _vm.columns,
          hasExtOperation: true,
          hasEnableEdit: false,
          extBtnLabel: _vm.$t("action.workorderExecute")
        },
        on: {
          findPage: _vm.findPage,
          handleEdit: _vm.handleEdit,
          handleDelete: _vm.handleDelete,
          handleExtEdit: _vm.handleExecute
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增照护工单",
            width: "400px",
            visible: _vm.dialogWorkorderVisible,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogWorkorderVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "customerForm",
              attrs: {
                model: _vm.customerFormData,
                "label-width": "80px",
                rules: _vm.customerFormDataRules,
                size: _vm.size,
                "label-position": "right"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "长者姓名", prop: "customerId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择客户", filterable: "" },
                      model: {
                        value: _vm.customerFormData.customerId,
                        callback: function($$v) {
                          _vm.$set(_vm.customerFormData, "customerId", $$v)
                        },
                        expression: "customerFormData.customerId"
                      }
                    },
                    _vm._l(_vm.customers, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: _vm.size },
                  nativeOn: {
                    click: function($event) {
                      _vm.dialogWorkorderVisible = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("action.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: _vm.size,
                    type: "primary",
                    loading: _vm.editCustomerLoading
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.submitCustomerForm.apply(null, arguments)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("action.confirm")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: !_vm.workorderFormData.id ? "新增" : "修改",
            width: "400px",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "workorderForm",
              staticStyle: { "text-align": "left" },
              attrs: {
                model: _vm.workorderFormData,
                rules: _vm.workorderFormDataRules,
                "label-width": "80px",
                size: _vm.size
              },
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.submitForm()
                }
              }
            },
            [
              false
                ? _c(
                    "el-form-item",
                    { attrs: { label: "ID", prop: "id" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true, "auto-complete": "off" },
                        model: {
                          value: _vm.workorderFormData.id,
                          callback: function($$v) {
                            _vm.$set(_vm.workorderFormData, "id", $$v)
                          },
                          expression: "workorderFormData.id"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "长者姓名", prop: "customerId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择长者", filterable: "" },
                      model: {
                        value: _vm.workorderFormData.customerId,
                        callback: function($$v) {
                          _vm.$set(_vm.workorderFormData, "customerId", $$v)
                        },
                        expression: "workorderFormData.customerId"
                      }
                    },
                    _vm._l(_vm.allCustomers, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发生时间", prop: "occurDatetime" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      align: "right",
                      type: "datetime",
                      placeholder: "发生时间",
                      "picker-options": _vm.pickerOptions
                    },
                    model: {
                      value: _vm.workorderFormData.occurDatetime,
                      callback: function($$v) {
                        _vm.$set(_vm.workorderFormData, "occurDatetime", $$v)
                      },
                      expression: "workorderFormData.occurDatetime"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "工单描述", prop: "urgentDesc" } },
                [
                  _c("el-input", {
                    attrs: { "auto-complete": "off" },
                    model: {
                      value: _vm.workorderFormData.urgentDesc,
                      callback: function($$v) {
                        _vm.$set(_vm.workorderFormData, "urgentDesc", $$v)
                      },
                      expression: "workorderFormData.urgentDesc"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "推送员工", prop: "workorderUrgentUsers" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { multiple: "", placeholder: "请选择员工" },
                      model: {
                        value: _vm.workorderFormData.workorderUrgentUsers,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.workorderFormData,
                            "workorderUrgentUsers",
                            $$v
                          )
                        },
                        expression: "workorderFormData.workorderUrgentUsers"
                      }
                    },
                    _vm._l(_vm.users, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: _vm.size },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("action.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: _vm.size,
                    type: "primary",
                    loading: _vm.editLoading
                  },
                  on: {
                    click: function($event) {
                      return _vm.submitForm()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("action.confirm")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-36d5366c", { render: render, staticRenderFns: staticRenderFns })
  }
}